<template>
  <v-dialog
    v-model="isInfoModalVisible"
    scrollable
    max-width="420px"
    :hide-overlay="true"
    :persistent="true"
    :no-click-animation="true"
  >
    <v-card
      v-if="infoModal"
    >
      <v-card-title>
        <v-row class="px-3 py-2 align-center">
          <div class="darkblue--text subtitle-2">
            {{ infoModal.title }}
          </div>

          <v-spacer />

          <v-btn
            :ripple="false"
            icon
            @click.stop="close()"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text v-if="infoModal.id === 'AT_RISK'">
        <p>
          At Risk line items are those that potentially will not deliver their
          scheduled impression goal in full by their end date. At Risk Line
          items are those that are delivering impressions AND have an OSI less
          than 100 as of the last data refresh time.
        </p>

        <p>
          Archived line items are excluded from all current reports.
        </p>
      </v-card-text>

      <v-card-text v-if="infoModal.id === 'STUCK_IN_PAUSED'">
        <p>
          Displays all line items that started within the
          last 14 days but whose status remains PAUSED.
        </p>

        <p>
          Archived line items are excluded from all current reports.
        </p>
      </v-card-text>

      <v-card-text v-if="infoModal.id === 'STUCK_IN_READY'">
        <p>
          Displays all line items that started within the
          last 14 days but whose status remains READY.
        </p>

        <p>
          Archived line items are excluded from all current reports.
        </p>
      </v-card-text>

      <v-card-text v-if="infoModal.id === 'MISSING_CREATIVES'">
        <p>
          Displays all DELIVERING line items that are missing
          at least one creative AND all READY line items that
          are missing at least one creative whose start dates
          are in the past or within the next 14 days.
        </p>

        <p>
          Archived line items are excluded from all current reports.
        </p>
      </v-card-text>

      <v-card-text v-if="infoModal.id === 'ZERO_IMPRESSIONS'">
        <p>
          Displays all line items with status of DELIVERING that
          delivered zero impressions yesterday. This excludes
          line items that are only set up for click tracking.
        </p>

        <p>
          Archived line items are excluded from all current reports.
        </p>
      </v-card-text>

      <v-card-text v-if="infoModal.id === 'ENDING_SOON'">
        <p>
          The Ending Soon report uses the following data parameters:
        </p>

        <p>
          Any currently delivering line items that will be ending within
          the next 3 days (today + 3)
        </p>

        <p>OR</p>

        <p>
          Any other line items that still have a status of DELIVERING
          in GAM with end dates within the last 4 days (today - 4)
        </p>

        <p>
          Archived line items are excluded from all current reports.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('tools')

export default {
  name: 'SummaryInfoModal',
  computed: {
    ...mapState({
      infoModal: (state) => state.infoModal,
      isInfoModalVisible: (state) => state.isInfoModalVisible,
    }),
  },
  methods: {
    close() {
      this.$store.dispatch('tools/hideSavedFilterInfo')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";
</style>
